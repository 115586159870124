import { AutocompleteSearchQuery, useAutocompleteSearchQuery } from 'generated';
import { useLanguage } from 'components/util/Language';
import { useEffect, useState } from 'react';
import { SearchMenuSection } from './model.Search';

export function useSearchSuggestionsModel({searchTerm, isSearchValid}: {searchTerm: string; isSearchValid: boolean;}) {
  const lang = useLanguage();

  const [suggestionResultsState, setSuggestionResultsState] = useState<SearchMenuSection | undefined>();

  const resetSuggestionResultsState = () => {
    setSuggestionResultsState(undefined);
  }

  const updateSuggestionsResultsState = (queryResult: AutocompleteSearchQuery) => {
    const suggestionResultOptions = queryResult?.autocompleteSearch?.map((items) => ({
      label: items.title,
      value: items.path,
    }));

    const newPageResultState = {
      optionType: { EN: 'Suggestions', FR: 'Suggestions' },
      items: suggestionResultOptions,
    };

    if (queryResult?.autocompleteSearch === undefined || queryResult?.autocompleteSearch.length === 0) {
      return resetSuggestionResultsState();
    }

    setSuggestionResultsState(newPageResultState);
  };
  const pageResultsQuery = useAutocompleteSearchQuery({
    variables: { language: lang, text: searchTerm },
    skip: !isSearchValid,
    returnPartialData: true,
    onCompleted: (data) => {
      updateSuggestionsResultsState(data);
    },
  });

  useEffect(() => {
    if (!isSearchValid) {
      resetSuggestionResultsState();
    }
  }, [isSearchValid]);

  return {
    suggestionResultsState,
    resetSuggestionResultsState,
    suggestionSearchPending: pageResultsQuery.loading
  }
}
