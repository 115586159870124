import { gql } from '@apollo/client';

// eslint-disable-next-line
gql`
  query sterling {
    unitsManyByRole {
      unit_code: code
      unit_name: name
    }
    assessmentMany(sort: DATE_DESC) {
      code
      title
      title_fr
      categoryDetails {
        code
        label
        label_fr
      }
    }
    permittedRoutes
  }
`;

// eslint-disable-next-line
gql`
  fragment ApiRoute on ContentOld {
    title
    itemtype
    key
    path
    order
    alternatePath
    titleFr
    noContent
  }
`;

// eslint-disable-next-line
gql`
  query apiRoutes($language: LanguageCode!, $itemType: String!) {
    apiRoutes: top_level_contents(language: $language, itemType: $itemType) {
      ...ApiRoute
      children {
        ...ApiRoute
        children {
          ...ApiRoute
          children {
            ...ApiRoute
            children {
              ...ApiRoute
              children {
                ...ApiRoute
              }
            }
          }
        }
      }
    }
  }
`;

// eslint-disable-next-line
export const CONTENT_SEARCH_QUERY = gql`
  query contentSearch($text: String!, $language: String!, $noLimit: Boolean) {
    contentSearch(text: $text, language: $language, noLimit: $noLimit) {
      _id
      title
      path
      search_score
      content
      ancestors {
        title
        path
      }
    }


  }
  `;

// eslint-disable-next-line
export const KEYWORD_SUGGESTION_QUERY = gql`
  query tagSuggestionSearch($text: String!, $language: String!) {
    tagSuggestionSearch(text: $text, language: $language)
    }
  `;

  export const AUTOCOMPLETE_SEARCH_QUERY = gql`
  query autocompleteSearch($text: String!, $language: String!) {
    autocompleteSearch(text: $text, language: $language) {
      title
      path
      ancestors {
        title
        path
      }
    }
  }
  `;

  export const TAG_SEARCH_QUERY = gql`
  query tagSearch($text: String!, $language: String!) {
    tagSearch(text: $text, language: $language) {
      title
      path
      ancestors {
        title
        path
      }
    }
  }
`;
