import React, { useState, useEffect } from 'react';
import { Hidden, Grid } from '@mui/material';
import {
  LogoutButton,
  Loading,
  LanguageToggle,
  ErrorHandle,
  Logo,
  // SelectOemPortal,
  useButtonStyles,
} from 'components';
import { MobileNav } from 'views/layout/navigation/Nav.Mobile';
import MenuIcon from '@mui/icons-material/Menu';
import clsx from 'clsx';
import { MainNav } from 'views/layout/navigation/Nav.Main';
import { SubNav } from 'views/layout/navigation/Nav.SubNav';
import { ClientRoute, clientRoutes, Routes } from 'routing/Routes';
import { useDispatch } from 'react-redux';
import { /*getToken,*/ CurrentOem, handleEnterKey } from 'utility';
import {
  setUnitCode,
  setError,
  setAssessmentCode,
} from 'sterling-redux/reducers/activeSelections';
import { useSterlingQuery, useApiRoutesQuery } from 'generated';
import { useLanguage } from 'components/util/Language';
import { NotificationBell } from 'views/pages/notifications/components/NotificationBell';
import { useNotifications } from '../pages/notifications/model.Notifications';
import SearchMenu from 'components/search/SearchMenu';
import { useSearch } from 'components/search/models/model.Search';
import { ContentSearchEngine } from 'components/fields/selectors/templates/SearchDropdown/ContentSearchEngine';
import { StRoleController } from 'suites/sterling/components/controllers/role/RoleController';

export const MainThemeContext = React.createContext({
  // Creates context with a default definition
  // eslint-disable-next-line
  setContentBackground: (bg: string) => {},
});

export function Main() {
  const dispatch = useDispatch();
  const buttonClasses = useButtonStyles();
  const [mobileOpen, setMobileOpen] = useState(false);
  const [contentBackground, setContentBackground] = useState('#fff');
  const [searchText, setSearchText] = React.useState('');
  const handleSeachTextChange = (event: React.ChangeEvent<HTMLInputElement>) => {
    setSearchText(event.target.value);
  };
  const searchModel = useSearch(searchText);

  const lang = useLanguage();

  const sterlingQuery = useSterlingQuery();
  const apiRoutesQuery = useApiRoutesQuery({ variables: { language: lang, itemType: '' } });
  const { unreadNotifications, unreadNotificationsCount, handleClick } = useNotifications();

  useEffect(() => {
    if (searchText) {
      setSearchText('');
    }
  }, [window.location.pathname]);
  useEffect(() => {
    if (sterlingQuery.data) {
      if (sterlingQuery.data.assessmentMany.length > 0) {
        dispatch(setAssessmentCode(sterlingQuery.data.assessmentMany[0].code));
      }

      if (sterlingQuery.data?.unitsManyByRole?.length > 0) {
        const unitName = [...sterlingQuery.data.unitsManyByRole].sort((unitA, unitB) =>
          unitA.unit_name > unitB.unit_name ? 1 : -1
        );
        dispatch(setUnitCode(unitName[0]?.unit_code));
      } else {
        dispatch(
          setError(
            lang === 'EN'
              ? 'No dealers registered to this account.'
              : `Aucun concessionnaire n'est enregistré sur ce compte.`
          )
        );
      }
    }
  }, [sterlingQuery.data]);

  if (sterlingQuery.loading && !sterlingQuery.data) return <Loading debug="sterlingQuery" />;
  if (sterlingQuery.error) return <ErrorHandle gqlError={sterlingQuery.error} />;
  if (!sterlingQuery.data) return <ErrorHandle error="Invalid data." />;

  if (apiRoutesQuery.loading && !apiRoutesQuery.data)
    return <Loading debug="apiRoutesQuery" />;
  if (apiRoutesQuery.error) return <ErrorHandle gqlError={apiRoutesQuery.error} />;
  if (!apiRoutesQuery.data || !apiRoutesQuery.data.apiRoutes)
    return <ErrorHandle error="Invalid data." />;

  if (apiRoutesQuery.data.apiRoutes?.some((r) => !r)) {
    return (
      <ErrorHandle error="Some API routes are null.  Check the integrity of the content documents." />
    );
  }

  const routeSort = (routeA: ClientRoute, routeB: ClientRoute) => routeA.order - routeB.order;
  const routes: ClientRoute[] = [
    ...(apiRoutesQuery.data.apiRoutes as unknown as ClientRoute[]),
    ...clientRoutes,
  ].sort(routeSort);

  const hasNotifications = sterlingQuery.data.permittedRoutes.some((r) =>
    /\/notifications/.test(r)
  );

  return (
    <Grid container className={`${CurrentOem.toLowerCase()} main-wrapper`}>
      <Hidden mdDown>
        <Grid item xs={12} md={4} lg={3} xl={2} className="nav-grid">
          <MainNav routes={routes} permittedRoutes={sterlingQuery.data.permittedRoutes} />
          <SubNav routes={routes} permittedRoutes={sterlingQuery.data.permittedRoutes} />
        </Grid>
      </Hidden>

      <Hidden mdUp>
        {mobileOpen && (
          <nav className="mobile-nav" style={{ zIndex: 2 }}>
            <MobileNav
              routes={routes}
              setMobileOpen={setMobileOpen}
              permittedRoutes={sterlingQuery.data.permittedRoutes}
            />
          </nav>
        )}
      </Hidden>

      <Hidden mdUp>
        {!mobileOpen && (
          <div className="mobile-nav-header">
            <Logo logoType="sub" />

            <div
              className={clsx(
                buttonClasses.button,
                buttonClasses.float,
                buttonClasses.hamburger
              )}
              style={{ marginTop: '10px' }}
            >
              {hasNotifications && (
                <NotificationBell
                  count={unreadNotificationsCount}
                  notifications={unreadNotifications}
                  handleClick={handleClick}
                />
              )}
              <div
                onClick={() => setMobileOpen(true)}
                onKeyPress={(e) => handleEnterKey(e, () => setMobileOpen(true))}
                role="menu"
                tabIndex={0}
              >
                <MenuIcon
                  style={{
                    width: '50px',
                    height: '50px',
                    marginRight: '25px',
                    marginLeft: '10px',
                  }}
                />
              </div>
            </div>
          </div>
        )}
      </Hidden>
      <MainThemeContext.Provider
        // eslint-disable-next-line react/jsx-no-constructed-context-values
        value={{
          setContentBackground: (bg: string) => {
            setContentBackground(bg);
          },
        }}
      >
        <Grid
          style={{ backgroundColor: contentBackground, overflowX: 'auto' }}
          className="content-wrapper"
          item
          xs={12}
          md={8}
          lg={9}
          xl={10}
        >
          <Hidden smDown>
            {/* {displayOemSelector && (
              <div
                style={{
                  boxSizing: 'border-box',
                  top: '15px',
                  paddingLeft: '20px',
                  position: 'absolute',
                  width: '150px',
                }}
              >
                <SelectOemPortal />
              </div>
            )} */}
            <Grid
              container
              direction="row"
              wrap="nowrap"
              justifyContent="end"
              className={buttonClasses.languageLogoutWrapper}
            >
              <Grid item sx={{ textAlign: 'left', pr: 4 }}>
                <SearchMenu
                  onChange={handleSeachTextChange}
                  searchValue={searchText}
                  searchTermClear={() => setSearchText('')}
                  label="Search for standards, modules or criteria..."
                  {...searchModel}
                />
              </Grid>
              <StRoleController excludeRoles={['SUPER_ADMIN', 'ADMIN', 'DEVELOPER', 'VENDOR']}>
                <Grid item>
                  <ContentSearchEngine />
                </Grid>
              </StRoleController>
              <Grid item>
                {hasNotifications && (
                  <NotificationBell
                    count={unreadNotificationsCount}
                    notifications={unreadNotifications}
                    handleClick={handleClick}
                  />
                )}
              </Grid>
              <Grid item>
                <LogoutButton />
              </Grid>
              <Grid item>
                <LanguageToggle />
              </Grid>
            </Grid>
          </Hidden>
          <Routes />
        </Grid>
      </MainThemeContext.Provider>
    </Grid>
  );
}

export default Main;
